import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { getUserBillConfig, postUserBillConfig } from "../../axios";
import { Button, Input, NavBar, Toast } from "antd-mobile";

export default function BillConfig() {
    const location = useLocation();
    const navigate = useNavigate();
    const [singleRate, setSingleRate] = useState(0);
    const [highSPCompoRate, setHighSPCompoRate] = useState(0);
    const [lowSPCompoRate, setLowSPCompoRate] = useState(0);
    const [lowSP, setLowSP] = useState(0);
    const [loading, setLoading] = useState(true);
    const userId = useMemo(() => location.state?.id, [location]);
    const back = () => {
        navigate(-1);
    }
    useEffect(() => {
        getUserBillConfig({ userId }).then(({ data }) => {
            setLoading(false);
            if(data.result) {
                setSingleRate(data.result.singleRate)
                setHighSPCompoRate(data.result.highSPCompoRate)
                setLowSP(data.result.lowSP)
                setLowSPCompoRate(data.result.lowSPCompoRate)
            }
        });
    }, [userId]);

    const handleUpdateBillConfig = () => {
        postUserBillConfig({
            userId,
            data: {
                singleRate,
                highSPCompoRate,
                lowSP,
                lowSPCompoRate,
            }
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                Toast.show("更新成功");
            }
        })
    }
    return (
        <div>
            <NavBar onBack={back}>
                对账配置
            </NavBar>
            <div style={{
                padding: "10px"
            }}>
                {
                    !loading && (
                        <table>
                            <tr>
                                <td>单关系数</td>
                                <td>
                                    <Input
                                        style={{ flex: 1 }}
                                        defaultValue={`${singleRate}`}
                                        onChange={(val) => setSingleRate(Number(val))}
                                        type="number" />
                                </td>
                            </tr>
                            <tr>
                                <td>串关系数</td>
                                <td>
                                    <Input
                                        defaultValue={`${highSPCompoRate}`}
                                        onChange={(val) => setHighSPCompoRate(Number(val))}
                                        type="number" />
                                </td>
                            </tr>
                            <tr>
                                <td>低SP界限</td>
                                <td>
                                    <Input
                                        defaultValue={`${lowSP}`}
                                        onChange={(val) => setLowSP(Number(val))}
                                        type="number" />
                                </td>
                            </tr>
                            <tr>
                                <td>低SP串关系数</td>
                                <td>
                                    <Input
                                        defaultValue={`${lowSPCompoRate}`}
                                        onChange={(val) => setLowSPCompoRate(Number(val))}
                                        type="number" />
                                </td>
                            </tr>
                        </table>
                    )
                }
            </div>
            <div>
                <Button block color="success" onClick={() => handleUpdateBillConfig()}>确定</Button>
            </div>
        </div>
    )
}