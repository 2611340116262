import { NavBar, Mask, Input, Button, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserBillDetail, postUserBillDetail } from "../../axios";
import "./BillDetail.css";
import { getDay } from "../../utils";

interface BillConfig {
    highSPCompoRate: number;
    createTime: number;
    lowSP: number;
    lowSPCompoRate: number;
    singleRate: number;
    userId: string
}

interface BillData {
    status: number;
    createTime: number;
    onlineBonus: number;
    onlineSingleAmount: number;
    onlineLowSPCompoAmount: number;
    onlineHighSPCompoAmount: number;
    offlineBonus: number;
    offlineSingleAmount?: number;
    offlineHighCompoAmount?: number;
    offlineLowSPCompoAmount?: number;
}

export default function BillDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const billId = useMemo(() => location.state.billId, [location]);
    const userId = useMemo(() => location.state.userId, [location]);
    const [userInfo, setUserInfo] = useState<{ username: string, remark: string }>();
    const [billConfig, setBillConfig] = useState<BillConfig>();
    const [billData, setBillData] = useState<BillData>();
    const [offlineSingleAmount, setOfflineSingleAmount] = useState(0);
    const [offlineHighSPCompoAmount, setOfflineHighSPCompoAmount] = useState(0);
    const [offlineLowSPCompoAmount, setOfflineLowSPCompoAmount] = useState(0);
    const [offlineBonus, setOfflineBonus] = useState(0);
    const [offlineMaskVisible, setOfflineMaskVisible] = useState(false);
    const totalSingleAmount = useMemo(() => {
        return ((billData?.onlineSingleAmount || 0) + offlineSingleAmount) * (billConfig?.singleRate || 0)
    }, [billConfig?.singleRate, billData?.onlineSingleAmount, offlineSingleAmount])
    const totalHighSPCompo = useMemo(() => {
        return ((billData?.onlineHighSPCompoAmount || 0) + offlineHighSPCompoAmount) * (billConfig?.highSPCompoRate || 0)
    }, [billData?.onlineHighSPCompoAmount, billConfig?.highSPCompoRate, offlineHighSPCompoAmount])
    const totalLowSPCompo = useMemo(() => {
        return ((billData?.onlineLowSPCompoAmount || 0) + offlineLowSPCompoAmount) * (billConfig?.lowSPCompoRate || 0)
    }, [billConfig?.lowSPCompoRate, billData?.onlineLowSPCompoAmount, offlineLowSPCompoAmount])
    const totalAmount = useMemo(() => {
        return ((billData?.onlineHighSPCompoAmount || 0) + offlineHighSPCompoAmount) * (billConfig?.highSPCompoRate || 0)
        + ((billData?.onlineLowSPCompoAmount || 0) + offlineLowSPCompoAmount) * (billConfig?.lowSPCompoRate || 0)
        + ((billData?.onlineSingleAmount || 0) + offlineSingleAmount) * (billConfig?.singleRate || 0)
    }, [billConfig?.highSPCompoRate, billConfig?.lowSPCompoRate, billConfig?.singleRate, billData?.onlineHighSPCompoAmount, billData?.onlineLowSPCompoAmount, billData?.onlineSingleAmount, offlineHighSPCompoAmount, offlineLowSPCompoAmount, offlineSingleAmount]);
    const totalProfit = useMemo(() => {
        return ((billData?.onlineHighSPCompoAmount || 0) + offlineHighSPCompoAmount) * (billConfig?.highSPCompoRate || 0)
        + ((billData?.onlineLowSPCompoAmount || 0) + offlineLowSPCompoAmount) * (billConfig?.lowSPCompoRate || 0)
        + ((billData?.onlineSingleAmount || 0) + offlineSingleAmount) * (billConfig?.singleRate || 0)
        - ((billData?.onlineBonus || 0) + offlineBonus)
    }, [billConfig?.highSPCompoRate, billConfig?.lowSPCompoRate, billConfig?.singleRate, billData?.onlineBonus, billData?.onlineHighSPCompoAmount, billData?.onlineLowSPCompoAmount, billData?.onlineSingleAmount, offlineBonus, offlineHighSPCompoAmount, offlineLowSPCompoAmount, offlineSingleAmount])


    const back = () => {
        navigate(-1);
    }
    useEffect(() => {
        getUserBillDetail({billId, userId}).then(({data}) => {
            setUserInfo(data.userInfo);
            setBillConfig(data.billConfig || {});
            setBillData(data.billData);
            setOfflineSingleAmount(data.billData.offlineSingleAmount || 0)
            setOfflineHighSPCompoAmount(data.billData.offlineHighSPCompoAmount || 0)
            setOfflineLowSPCompoAmount(data.billData.offlineLowSPCompoAmount || 0)
            setOfflineBonus(data.billData.offlineBonus || 0)
            if (data.billData.status === 0) {
                setOfflineMaskVisible(true);
            }
        })
    }, [billId, userId]);
    const handleUpdateBill = () => {
        postUserBillDetail({
            billId,
            data: {
                status: 1,
                onlineBonus: billData?.onlineBonus,
                onlineSingleAmount: billData?.onlineSingleAmount,
                onlineLowSPCompoAmount: billData?.onlineLowSPCompoAmount,
                onlineHighSPCompoAmount: billData?.onlineHighSPCompoAmount,
                offlineBonus,
                offlineSingleAmount,
                offlineHighSPCompoAmount,
                offlineLowSPCompoAmount,
            }
        }).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data);
            } else {
                navigate(-1);
            }
        })
    };

    return <div>
        <div className="bill-detail">
            <NavBar onBack={back} right={
                <div onClick={() => setOfflineMaskVisible(true)}>
                    线下填写
                </div>}>
                对账信息
            </NavBar>
            <div>
                <h2 style={{ textAlign: "center" }}>{userInfo?.remark || userInfo?.username}-{getDay(billData?.createTime || 0)}</h2>
                <table border={2} style={{ width: "100%", border: "auto" }}>
                    <tr>
                        <td style={{ width: "30%" }}>线上单关</td>
                        <td style={{ width: "20%" }}>{ billData?.onlineSingleAmount }</td>
                        <td style={{ width: "18%" }} rowSpan={2}>{ (billData?.onlineSingleAmount || 0) + offlineSingleAmount }</td>
                        <td style={{ width: "14%" }} rowSpan={2}>{ billConfig?.singleRate }</td>
                        <td style={{ width: "18%" }} rowSpan={2}>{ totalSingleAmount.toFixed(2) }</td>
                    </tr>
                    <tr>
                        <td>线下单关</td>
                        <td>{offlineSingleAmount}</td>
                    </tr>
                    <tr>
                        <td>线上高SP串</td>
                        <td>{ billData?.onlineHighSPCompoAmount }</td>
                        <td rowSpan={2}>{ (billData?.onlineHighSPCompoAmount || 0) + offlineHighSPCompoAmount }</td>
                        <td rowSpan={2}>{ billConfig?.highSPCompoRate }</td>
                        <td rowSpan={2}>{ totalHighSPCompo.toFixed(2) }</td>
                    </tr>
                    <tr>
                        <td>线下高SP串</td>
                        <td>{ offlineHighSPCompoAmount }</td>
                    </tr>
                    <tr>
                        <td>线上低SP串</td>
                        <td>{ billData?.onlineLowSPCompoAmount }</td>
                        <td rowSpan={2}>{ (billData?.onlineLowSPCompoAmount || 0) + offlineLowSPCompoAmount }</td>
                        <td rowSpan={2}>{ billConfig?.lowSPCompoRate }</td>
                        <td rowSpan={2}>{ totalLowSPCompo.toFixed(2) }</td>
                    </tr>
                    <tr>
                        <td>线下低SP串</td>
                        <td>{ offlineLowSPCompoAmount }</td>
                    </tr>
                    <tr>
                        <td colSpan={5}>总计: {totalAmount.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                        <td colSpan={2}>线上派奖: { billData?.onlineBonus.toFixed(2) }</td>
                        <td colSpan={3}>线下派奖: { offlineBonus.toFixed(2) } </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>利润: {totalProfit.toFixed(2)}</td>
                    </tr> */}
                </table>
                <div style={{
                    marginTop: "10px"
                }} onClick={() => handleUpdateBill()}>
                    <Button color="success" block>确认</Button>
                </div>
            </div>
            <Mask visible={offlineMaskVisible}>
                <div style={{
                    width: "80vw",
                    margin: "60px auto",
                    background: "white",
                    padding: "10px"
                }}>
                    <div style={{ display: "flex", borderBottom: "1px solid black"}}>
                       <div style={{ flex: 1, borderRight: "1px solid black", marginRight: "5px" }}>线下单关</div>
                       <Input style={{ flex: 1 }} defaultValue={`${offlineSingleAmount}`} onChange={(val) => setOfflineSingleAmount(Number(val))} type="number" placeholder='线下单关'/>
                    </div>
                    <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                       <div style={{ flex: 1, width: "100px", borderRight: "1px solid black", marginRight: "5px" }}>线下高SP串关</div>
                       <Input style={{ flex: 1 }} defaultValue={`${offlineHighSPCompoAmount}`} onChange={(val) => setOfflineHighSPCompoAmount(Number(val))} type="number" placeholder='线下高SP串关' />
                    </div>
                    <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                       <div style={{ flex: 1, width: "100px", borderRight: "1px solid black", marginRight: "5px" }}>线下低SP串关</div>
                       <Input style={{ flex: 1 }} defaultValue={`${offlineLowSPCompoAmount}`} onChange={(val) => setOfflineLowSPCompoAmount(Number(val))} type="number" placeholder='线下低SP串关' />
                    </div>
                    <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                       <div style={{ flex: 1, width: "100px", borderRight: "1px solid black", marginRight: "5px" }}>线下获奖</div>
                       <Input style={{ flex: 1 }} defaultValue={`${offlineBonus}`} onChange={(val) => setOfflineBonus(Number(val))} type="number" placeholder='线下获奖' />
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <Button color="success" block onClick={() => setOfflineMaskVisible(false)}>确定</Button>
                    </div>
                </div>
            </Mask>
        </div>
    </div>
}