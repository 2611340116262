import React, { useEffect, useMemo, useState } from "react";
import { List, Image, ImageUploader, ImageUploadItem, Button, NavBar, Toast, Modal, Radio, Space, Mask, Form, Input, ImageViewer, Dialog } from "antd-mobile";
import { v4 as uuidv4 } from 'uuid';
import { getManagerBetDetail, postManagerUploadTicketsImage, postPayBonus, postRecallOrder, postTakeBet, postUpdateBonus, postManagerUploadTicketsBet, postHardRecallOrder } from "../../axios/index.js";
import { cnNameMap, status2CN } from "../../constant/cnNameMap.ts";
import { getTime, deepCopy } from "../../utils/index.ts";
import { useNavigate, useParams } from "react-router-dom";
import MatchBetDetail from "../../components/MatchBetDetail.tsx";
import PickThreeBetDetail from "../../components/PickThreeBetDetail.tsx";
import "./BetDetail.css";
import { PlayType } from "../../constant/betPlayType.ts";
import { getSTSClient, refreshToken } from "../../sdk/sts.ts";
import copy from "copy-to-clipboard";
import ChampionBetDetail from "../../components/ChampionBetDetail.tsx";
import { useSelector } from "react-redux";

const recallBetReason: string[] = ["订单逾期", "票数太多", "订单较多，忙不过来", "联系不到客户", "中心限制订单，无法出票", "来不及出票，赔率变化"]

export default function BetDetail() {
    const navigate = useNavigate();
    const params = useParams();
    const shop = useSelector((state) => state.shop.shop);
    const [disableOrder, setDisableOrder] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [ossClient, setOssClient] = useState<any>();
    const [betDetail, setBetDetail] = useState<any>({items:[]});
    const [matchList, setMatchList] = useState<any[]>([]);
    const [updateBonus, setUpdateBonus] = useState<string>("0");
    const [updateBonusModalVisible, setUpdateBonusModalVisible] = useState(false);
    const [oddsForBonus, setOddsForBonus] = useState<any>();
    const [oddsModalVisible, setOddsModalVisible] = useState(false);
    const [uploadOddsForBonus, setUploadOddsForBonus] = useState<any>({})
    const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
    const [recallModalVisible, setRecallModalVisible] = useState(false);
    const [hardRecallModalVisible, setHardRecallModalVisible] = useState(false);
    const [payPassword, setPayPassword] = useState("");
    const [recallReasonIndex, setRecallReasonIndex] = useState(4);
    const [groupBuyTaskDetail, setGroupBuyTaskDetail] = useState({});
    const [groupBuyItemList, setGroupBuyItemList] = useState([]);
    const [imgModalVisible, setImgModalVisible] = useState(false);
    const [imgModalSrc, SetImgModalSrc] = useState("");
    const isMasterShop = useMemo(() => shop._id === "6528737d3f59abb12181c477", [shop]);
    function updateBetDetail() {
        getManagerBetDetail({ betId: params.id })
        .then(({data: { bet, groupBuyTaskDetail, groupBuyItemList }}) => {
            setBetDetail(bet);
            if(bet.status === "4") {
                setUpdateBonus(bet.bonus);
            }
            const { items, type, matchInfo, playType } = bet;
            if(playType === PlayType.GroupBuy) {
                setGroupBuyTaskDetail(groupBuyTaskDetail || {});
                setGroupBuyItemList(groupBuyItemList || []);
            }
            if(type !== "pickThree") {
                const matchGroup: any = {};
                const { matchList, oddsForBonus: _oddsForBonus } = matchInfo;
                matchList.forEach((match:any) => {
                    matchGroup[match._id] = match;
                });
                setMatchList(matchList);
                if(_oddsForBonus) {
                    setOddsForBonus(_oddsForBonus);
                    setUploadOddsForBonus(_oddsForBonus);
                } else {
                    const oddsForBonus: any = {};
                    for(let match of matchList) {
                        const matchId = match._id;
                        const item = items[matchId];
                        oddsForBonus[matchId] = {};
                        for(let type in item) {
                            oddsForBonus[matchId][type] = {
                                goalLine: item[type].goalLine,
                            };
                            let targetList = item[type].target;
                            for(let target of targetList) {
                                oddsForBonus[matchId][type][target] = match["odds"][type][target];
                            }
                        }
                    };
                    setOddsForBonus(deepCopy(oddsForBonus));
                    setUploadOddsForBonus(deepCopy(oddsForBonus));
                }
            }
        });
    }
    useEffect(() => {
        const currentDate = new Date();
        const currentTime = currentDate.getTime();
        const currentDay = currentDate.getDay();
        const _startTime = currentDate.setHours(11, 0, 0, 0);
        let _endTime = currentDate.setHours(22, 0, 0, 0);
        if(currentDay > 5) {
            _endTime = currentDate.setHours(23, 0, 0, 0);
        }
        setStartTime(_startTime);
        setEndTime(_endTime);
        // if(currentTime > _startTime && currentTime < _endTime) {
        //     setDisableOrder(false);
        // }
        updateBetDetail();
        let _ossClient = getSTSClient();
        if(_ossClient) {
            setOssClient(_ossClient);
        } else {
            refreshToken().then((data) => {
                setOssClient(data);
            })
        }
    }, []);
    
    const handleOrderTicket = async() => {
        postTakeBet({betId: betDetail._id}).then(({data}) => {
            if(data.status === "error") {
                if (data.errorCode === 10001) {
                    Dialog.show({
                        content: '店铺余额不足以支付服务费无法接单，是否充值',
                        closeOnAction: true,
                        onAction: (action) => {
                            if(action.key === 'charge') {
                                navigate('/manager/shop/charge');
                            }
                        },
                        actions: [
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                            {
                                key: 'charge',
                                text: '充值',
                            }
                        ]
                    })
                } else {
                    Toast.show(data.errorMessage);
                }
            } else {
                updateBetDetail();
            }
        });
    }

    const handleBonusChange = (val: string) => {
        setUpdateBonus(val);
    }

    const handleBonusCancel = () => {
        setUpdateBonus(betDetail.bonus);
        setUpdateBonusModalVisible(false);
    }

    const handleBonusConfirm = () => {
        setUpdateBonusModalVisible(false);
        postUpdateBonus(({betId: betDetail._id, bonus: updateBonus})).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage)
            } else {
                betDetail.bonus = Number(updateBonus);
                setBetDetail({...betDetail});
            }
        })
    }

    const handleOddsForBonusChange = (matchId: string, type: string, target: string, value: string) => {
        uploadOddsForBonus[matchId][type][target] = Number(value);
        setUploadOddsForBonus({...uploadOddsForBonus});
    };

    const handleOddsConfirm = () => {
        setOddsForBonus(uploadOddsForBonus);
        setOddsModalVisible(false);
        handleUploadTicket();
    }

    const handleOddsCancel = () => {
        setUploadOddsForBonus(deepCopy(oddsForBonus));
        setOddsModalVisible(false);
    }

    const handleFileUpload = async (file: File) => {
        const _ossClient = ossClient || getSTSClient();
        if(_ossClient) {
            try {
                const fileFormat = (file.name).split('.');
                const res = await _ossClient.put(`tickets/${uuidv4()}.${fileFormat[fileFormat.length - 1]}`, file);
                return { url: `https://oss.tc88xx.com/${res.name}?x-oss-process=image/resize,w_1024,m_lfit` }
            } catch {
                Toast.show("图片上传失败, 请重新上传")
            }
            throw new Error('');
        } else {
            const { data } = await postManagerUploadTicketsImage({ image: file });
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                throw new Error('');
            } else {
                return { url: data.result.url }
            }
        }
    }

    const handleUploadTicket = async() => {
        const ticketList = fileList.map(file => file.url);
        if(ticketList.length === 0) {
            Toast.show("必须上传票据");
            return;
        }
        postManagerUploadTicketsBet({
            betId: betDetail._id,
            oddsForBonus: uploadOddsForBonus,
            ticketList,
        }).then(({data}) => {
            if(data.status === "error") {
                if (data.errorCode === 10001) {
                    Dialog.show({
                        content: '店铺余额不足以支付服务费无法出票，是否充值',
                        closeOnAction: true,
                        onAction: (action) => {
                            if(action.key === 'charge') {
                                navigate('/manager/shop/charge');
                            }
                        },
                        actions: [
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                            {
                                key: 'charge',
                                text: '充值',
                            }
                        ]
                    })
                } else {
                    Toast.show(data.errorMessage);
                }
            } else {
                Toast.show("出票成功");
                navigate(-1);
            }
        }).catch(() => {
            Toast.show("出票失败");
        })
    };
    const handleQuickUploadTicket = async() => {
        postManagerUploadTicketsBet({
            betId: betDetail._id,
            oddsForBonus: uploadOddsForBonus,
            ticketList: ['https://tclottery.oss-cn-hangzhou.aliyuncs.com/tickets/000049d0-ea7e-4ece-9eed-2d2979082817.jpeg'],
        }).then(({data}) => {
            if(data.status === "error") {
                if (data.errorCode === 10001) {
                    Dialog.show({
                        content: '店铺余额不足以支付服务费无法出票，是否充值',
                        closeOnAction: true,
                        onAction: (action) => {
                            if(action.key === 'charge') {
                                navigate('/manager/shop/charge');
                            }
                        },
                        actions: [
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                            {
                                key: 'charge',
                                text: '充值',
                            }
                        ]
                    })
                } else {
                    Toast.show(data.errorMessage);
                }
            } else {
                Toast.show("出票成功");
                navigate(-1);
            }
        }).catch(() => {
            Toast.show("出票失败");
        })
    };
    const handlePayTicketsClick = () => {
        postPayBonus({
            betList: [params.id],
            special: true,
        }).then(() => {
            Toast.show("派奖成功");
            navigate(-1);
        }).catch(() => {
            Toast.show("派奖失败");
        });
    };
    const handleRecallTicket = async() => {
        postRecallOrder({
            betId: params.id,
            reason: recallBetReason[recallReasonIndex],
        }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                Toast.show("撤单成功");
                navigate(-1);
            }
        }).catch(() => {
            Toast.show("撤单失败");
        })
    }

    const handleHardRecallTicket = async() => {
        if(!payPassword) {
            Toast.show("请输入支付密码");
            return;
        }
        postHardRecallOrder({
            betId: params.id,
            payPassword,
        }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                Toast.show("撤单成功");
                navigate(-1);
            }
        }).catch(() => {
            Toast.show("撤单失败");
        })
    }

    const handleViewSplitTickets = async() => {
        navigate(`/manager/bet/splittickets/${params.id}`, { state: { bet: betDetail } });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <NavBar onBack={() => navigate(-1)}>
                {cnNameMap[betDetail.type]} ({cnNameMap[betDetail.subType]})
            </NavBar>
            <div style={{ flex: "1 1", overflowY: "auto", paddingBottom: "30px" }}>
                <div className="bet-status">
                    <div className="item">
                        <span style={{color: "red"}}>{betDetail.spend}元</span> <br />
                        <span>方案金额</span>
                    </div>
                    <div className="item">
                        <span>{status2CN[betDetail.status]}</span> <br />
                        <span>方案状态</span>
                    </div>
                    <div className="item">
                        <span>{betDetail.bonus?.toFixed(2) || "-"}</span> <br />
                        <span>税后奖金</span>
                    </div>
                </div>
                { betDetail.recallReason && (
                    <div style={{ border: "1px solid red", margin: "15px", padding: "5px" }}>{ betDetail.recallReason }</div>
                ) }
                {
                    ["basketball", 'football', 'beijing'].includes(betDetail.type) && (
                        <MatchBetDetail bet={betDetail} />
                    )
                }
                {
                    betDetail.type === "pickThree" && (
                        <PickThreeBetDetail pickthreeInfo={betDetail.pickthreeInfo} items={betDetail.items}/>
                    )
                }
                {
                    betDetail.type === 'champion' && (
                        <ChampionBetDetail bet={betDetail}/>
                    )
                }
                
                <List header=''>
                    {
                        betDetail.type !== 'champion' && betDetail.type !== 'pickThree' && (
                            <div style={{ textAlign: "center" }}><Button onClick={handleViewSplitTickets}>查看拆票明细</Button></div>
                        )
                    }
                    {
                        betDetail.status === "1" && (
                            <List.Item>
                                <div>
                                    <h4>上传图片</h4>
                                    <ImageUploader
                                        // capture="user"
                                        showFailed={false}
                                        value={fileList}
                                        onChange={setFileList}
                                        upload={handleFileUpload as any} />
                                </div>
                            </List.Item>
                        )
                    }
                    {
                        (["2", "3", "4", "5"].includes(betDetail.status)) && (
                            <Space wrap>
                                {
                                    betDetail.ticketPath?.map((path: string) => (
                                        <Image src={`${path}?x-oss-process=image/format,heic`} width={100} height={100} fit='fill'
                                            fallback={
                                                <Image src={path} width={100} height={100} fit='fill'  onClick={() => {
                                                        setImgModalVisible(true);
                                                        SetImgModalSrc(path)
                                                    }}
                                                />
                                            }
                                            onClick={() => {
                                            setImgModalVisible(true);
                                            SetImgModalSrc(path)
                                        }}/>

                                    ))
                                }
                            </Space>
                        )
                    }
                    <List.Item extra={betDetail.userId?.remark || betDetail.userId?.username}>
                        下单用户
                    </List.Item>
                    {
                        betDetail?.playType === PlayType.GroupBuy && (
                            <>
                                <List.Item extra={
                                    <div>{`${(groupBuyTaskDetail?.totalPurchasedCopies / groupBuyTaskDetail?.totalCopies * 100).toFixed(2)}% + 保${(groupBuyTaskDetail?.guaranteedCopies / groupBuyTaskDetail?.totalCopies * 100).toFixed(2)}%`}</div>
                                }>
                                    进度+保底
                                </List.Item>
                                <List.Item extra={`${(groupBuyTaskDetail?.commissionPercentage * 100).toFixed(2)}%`}>
                                    中奖佣金
                                </List.Item>
                                <List.Item 
                                    extra={`${groupBuyItemList.length}人，共${(groupBuyTaskDetail.totalPurchasedCopies * groupBuyTaskDetail.amountPerCopy).toFixed(2)}元`}
                                    clickable
                                    onClick={() => navigate("/manager/groupbuy/itemdetail", { state: { groupBuyItemList, groupBuyTaskDetail } })}>
                                    认购人数
                                </List.Item>
                            </>
                        )
                    }
                    <List.Item extra={getTime(betDetail.userBuyTime)}>
                        下单时间
                    </List.Item>
                    {
                        betDetail.recallTime ? (
                            <List.Item extra={getTime(betDetail.recallTime)}>
                                方案撤单时间
                            </List.Item>
                        ) : (
                            <List.Item extra={getTime(betDetail.closeTime)}>
                                方案截止时间
                            </List.Item>
                        )
                        
                    }
                    {betDetail.managerUpdateTime && (
                        <List.Item extra={getTime(betDetail.managerUpdateTime)}>
                            出票时间
                        </List.Item>
                    )}
                    <List.Item extra={<span>
                        {betDetail.betId}
                        <Button color='primary' fill='none' size="small" onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                copy(betDetail.betId)
                            }}>
                                复制
                            </Button>
                    </span>}>
                        订单编号
                    </List.Item>
                </List>
                {
                    (betDetail.status === "0" || betDetail.status === "1") && (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <Button disabled={disableOrder} color="danger" onClick={() => setRecallModalVisible(true)}>撤单</Button>
                        </div>
                    )
                }
                {
                    (betDetail.status === "2" && isMasterShop) && (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <Button disabled={disableOrder} color="danger" onClick={() => setHardRecallModalVisible(true)}>强制撤单</Button>
                        </div>
                    )
                }
            </div>
            <Modal
                visible={recallModalVisible}
                content={(
                    <Radio.Group defaultValue={4} onChange={(val) => setRecallReasonIndex(val as number)}>
                        <Space direction='vertical'>
                            {
                                recallBetReason.map((val, index) => (
                                    <Radio value={index}>{val}</Radio>
                                ))
                            }
                        </Space>
                    </Radio.Group>
                )}
                closeOnAction
                onAction={(action) => {
                    setRecallModalVisible(false);
                    if(action.key === "confirm") {
                        handleRecallTicket()
                    }
                }}
                actions={[
                    {
                        key: 'confirm',
                        text: '确认撤单',
                    },
                    {
                        key: 'recall',
                        text: '取消',
                    }
                ]}
            />
            <Modal
                visible={hardRecallModalVisible}
                content={(
                    <div>
                        <div style={{ textAlign: "center", fontSize: "20px" }}>强制撤单</div>
                        <Input placeholder="支付密码" onChange={setPayPassword}/>
                    </div>
                )}
                closeOnAction
                onAction={(action) => {
                    setHardRecallModalVisible(false);
                    if(action.key === "confirm") {
                        handleHardRecallTicket()
                    }
                }}
                actions={[
                    {
                        key: 'confirm',
                        text: '确认撤单',
                    },
                    {
                        key: 'recall',
                        text: '取消',
                    }
                ]}
            />
            {
                betDetail.status === "0" && (
                    <Button color="primary" disabled={disableOrder} block onClick={handleOrderTicket}>接单</Button>
                )
            }
            <div style={{ display: "flex" }}>
                {
                    betDetail.status === "1" && (
                        <Button color="primary" disabled={disableOrder} block onClick={() => {
                            if(['basketball', 'football'].includes(betDetail.type) ) {
                                setOddsModalVisible(true)
                            } else {
                                handleUploadTicket();
                            }
                        }}>出票</Button>
                    )
                }
                {
                    betDetail.status === "1" && isMasterShop && (
                        <Button color="warning" disabled={disableOrder} block onClick={() => {
                            window.open(`http://finance.tc88xx.com/#/ticket/${betDetail._id}`)
                        }}>打票</Button>
                    )
                }
                {
                    betDetail.status === "1" && isMasterShop && (
                        <Button color="success" disabled={disableOrder} block
                            onClick={() => {handleQuickUploadTicket()}}>一键出票</Button>
                    )
                }
            </div>
            {
                betDetail.status === "4" && (
                    <div style={{ display: "flex", height:"50px" }}>
                        <Button style={{ width: "120px" }} onClick={() => setUpdateBonusModalVisible(true)}>修改奖金</Button>
                        <Button color="primary" disabled={disableOrder} block onClick={handlePayTicketsClick}>派奖</Button>
                    </div>
                )
            }
            <Mask visible={updateBonusModalVisible} onMaskClick={() => handleBonusCancel()}>
                <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems:"center" }}>
                    <div style={{ backgroundColor: "white" }}>
                        <Form
                            layout='horizontal'
                            footer={
                                <div style={{ display: "flex" }}>
                                    <Button block color='warning' size='large' onClick={handleBonusCancel}>
                                        取消
                                    </Button>
                                    <Button block color='primary' size='large' onClick={handleBonusConfirm}>
                                        确认
                                    </Button>
                                </div>
                            }
                        >
                            <Form.Item label='税后奖金'>
                                <Input value={updateBonus} onChange={handleBonusChange} type="number"/>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Mask>
            <Mask visible={oddsModalVisible} onMaskClick={() => handleOddsCancel()}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", }}>
                    <div style={{ backgroundColor: "white", width: "80%", padding: "10px", maxHeight: "80%", overflow: "auto" }}>
                        <table>
                            <tr>
                                <th>场次</th>
                                <th>核对赔率</th>
                            </tr>
                            {
                                matchList.map((match) => {
                                    const oddsList = Object.entries(uploadOddsForBonus[match._id]);
                                    return (
                                        <tr>
                                            <td style={{ width: "60px" }}>{match.matchNumStr}</td>
                                            <td>
                                                <Form layout='horizontal'>
                                                    {
                                                        betDetail.type === 'basketball' ? (
                                                            <Form.Header>{`[客]${match.awayTeamAbbName} vs ${match.homeTeamAbbName}[主]`}</Form.Header>
                                                        ) : (
                                                            <Form.Header>{`${match.homeTeamAbbName} vs ${match.awayTeamAbbName}`}</Form.Header>
                                                        )
                                                    }
                                                    {
                                                        oddsList.map(([type, odds]) => {
                                                            const target = Object.keys(odds).filter((val) => val!== "goalLine");
                                                            return (
                                                                <>
                                                                    {target.map((target) => {
                                                                        return (
                                                                            <Form.Item label={cnNameMap[`${type}_${target}`] || cnNameMap[target]}>
                                                                                <Input type="number" value={`${odds[target]}`} onChange={(val) => handleOddsForBonusChange(match._id, type, target, val)}/>
                                                                            </Form.Item>
                                                                        )
                                                                    })}
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    
                                                </Form>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "flex", "justifyContent": "space-between" }}>
                            <Button onClick={handleOddsCancel}>
                                取消
                            </Button>
                            <Button onClick={handleOddsConfirm} color="primary">
                                核对确认
                            </Button>
                        </div>
                    </div>
                </div>
            </Mask>
            <ImageViewer
                image={imgModalSrc}
                visible={imgModalVisible}
                onClose={() => {
                    setImgModalVisible(false)
                }}
            />
        </div>
    )
}
