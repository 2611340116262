import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavBar, InfiniteScroll, Toast } from "antd-mobile";
import { getUserBillList } from "../../axios/index.js";
import { getDay, getTime } from "../../utils/index.ts";
import { useNavigate, useLocation } from "react-router-dom";

const status2CN = ["未确认", "已确认"]

export default function UserDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const [billList, setBillList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const userId = useMemo(() => {
        return location?.state?.id;
    }, [location?.state?.id])
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            userId,
            pageNum: pagenation.current.pageNum,
        };
        const { data } = await getUserBillList(query);
        if(data.status === "error") {
            Toast.show(data.errorMessage);
        } else {
            pagenation.current.pageNum++;
            setBillList([...billList, ...data.result]);
            setHasMore(data.result.length === 20);
        }
        pagenation.current.loading = false;
    }

    const back = () => {
        navigate(-1);
    }
    return (
        <div className="bill-list" style={{
            height: "100%",
            overflow: "auto",
            background: "whitesmoke",
        }}>
            <NavBar onBack={back} right={
                <div onClick={() => navigate("/manager/bill/config", { state: { id: userId } })}>对账配置</div>
            }>
                用户账单
            </NavBar>
            <div className="content">
                {
                    billList.map((bill: any) => {
                        return (
                            <div onClick={() => navigate("/manager/bill/detail", { state: { billId: bill._id, userId } })}
                                style={{
                                    display: "flex",
                                    padding: "20px",
                                    background: "white",
                                    justifyContent: "space-between",
                                    margin: "10px"
                                }}>
                                <span>{ getDay(bill.createTime) }</span>
                                <span>{ status2CN[bill.status] }</span>
                            </div>
                        )
                    })
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            </div>
        </div>
    )
}

